import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { Seo, Layout } from "../../components"

const NouvelAir = () => {
  return (
    <Layout>
      <Seo title="Koomet - Nos réalisations" />
      <div className="pt-16 pb-12 xl:pb-20 flex flex-col">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="py-16">
            <div className="text-center">
              <p className="w-10 h-10 rounded-full text-violet mx-auto mb-5">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 215 215" fill="currentColor">
                  <g>
                    <polygon points="183.986,183 169.621,143 47.046,143 32.68,183 11.5,183 11.5,215 203.5,215 203.5,183  " />
                    <polygon points="118.265,0 98.402,0 80.804,49 135.862,49  " />
                    <polygon points="143.045,69 73.622,69 54.229,123 162.438,123  " />
                  </g>
                </svg>
              </p>
              <h1 className="mt-2 text-4xl font-extrabold text-gris dark:text-white tracking-tight sm:text-5xl">Page en travaux.</h1>
              <p className="mt-2 text-base text-gray-500 dark:text-gray-100">Désolé mais cette page est en travaux !</p>
              <div className="mt-6">
                <AniLink cover bg="#6377E9" direction="up" duration={0.6} className="text-base font-medium text-violet" to="/">
                  Revenir à l'accueil <span aria-hidden="true"> &rarr;</span>
                </AniLink>
              </div>
            </div>
          </div>
        </main>
      </div>
    </Layout>
  )
}

export default NouvelAir
